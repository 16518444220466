module.exports = {
  enabled: !true,
  langKeyDefault: 'en',
  langs: ['en', 'es'],
  pagesPaths: ['/src/content', '/src/pages'],
  langKeyForNull: 'en',
  useLangKeyLayout: false,
  prefixDefault: false,
  markdownRemark: {
  },
};
