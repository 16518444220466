const normalizeUrl = (url: string) => (url.startsWith('/') ? url.substr(1) : url);

const langToBase = (lang: string) => (['', 'en'].includes(lang) ? '' : `/${lang}`);

export const withLangURL = (langBase: string, url: string): string =>
  `${langToBase(langBase)}/${normalizeUrl(url)}`;

export const withLangPathName = (lang: string, pathname: string) => {
  const url = pathname
    .split('/')
    .filter((d: string) => d !== 'es')
    .join('/');
  return `${langToBase(lang)}/${normalizeUrl(url)}`;
};
