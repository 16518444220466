import React, { useCallback } from 'react';
import cx from 'classnames';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { useLocation } from '@reach/router';

import { langs } from '../../../i18n/config';
import { withLangPathName } from '../../utils/langUtils';

const Switcher = styled.div`
  display: flex;
  text-transform: uppercase;
  margin-left: 1rem;
  padding-left: 1rem;
  border-left: 1px solid #fff;

  a {
    color: #fafafa;
    display: inline-block;
  }

  a:first-of-type {
    margin-right: 0.5rem;
  }

  a.current {
    font-weight: 900;
    text-decoration: underline;
  }
`;

export const LanguageSwitcher: React.FC = () => {
  const location = useLocation();
  const { i18n } = useTranslation();

  const changeLanguage = useCallback(
    (e: any) => {
      i18n.changeLanguage(e.target.dataset.lang);
    },
    [i18n],
  );

  return (
    <Switcher>
      {langs.map((lang: string) => (
        <Link
          key={lang}
          data-lang={lang}
          className={cx({ current: i18n.language === lang })}
          to={withLangPathName(lang, location.pathname)}
          title={lang}
          onClick={changeLanguage}
        >
          {lang}
        </Link>
      ))}
    </Switcher>
  );
};
