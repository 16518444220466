// tslint:disable:no-http-string
import { Link } from 'gatsby';
import * as React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';

import { SocialLink } from '../../styles/shared';
import config from '../../website-config';
import Facebook from '../icons/facebook';
import Twitter from '../icons/twitter';
import SubscribeModal from '../subscribe/SubscribeOverlay';
import SiteNavLogo from './SiteNavLogo';
import { withLangURL } from '../../utils/langUtils';
import { LanguageSwitcher } from '../LanguageSwitcher';

const HomeNavRaise = css`
  @media (min-width: 900px) {
    position: relative;
    top: -70px;
  }
`;

const SiteNavStyles = css`
  position: relative;
  z-index: 300;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  overflow-y: hidden;
  height: 40px;
  font-size: 1.2rem;
  background: #000;
  border-radius: 0.5rem;
  @media (min-width: 900px) {
    padding: 0 1rem;
  }
`;

const SiteNavLeft = styled.div`
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  margin-right: 10px;
  padding-bottom: 80px;
  letter-spacing: 0.4px;
  white-space: nowrap;

  -ms-overflow-scrolling: touch;

  @media (max-width: 700px) {
    margin-right: 0;
    padding-left: 4vw;
  }
`;

const NavStyles = css`
  display: flex;
  margin: 0 0 0 -12px;
  padding: 0;
  list-style: none;

  li {
    display: block;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
  }

  li a {
    display: block;
    margin: 0;
    padding: 10px 12px;
    color: #f0f0f0;
  }

  li a:hover {
    color: #fff;
    text-decoration: none;
  }
`;

const SiteNavRight = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 40px;

  @media (max-width: 700px) {
    display: none;
  }
`;

const SocialLinks = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  a:last-of-type {
    padding-right: 20px;
  }
`;

const SubscribeButton = styled.a`
  display: block;
  padding: 4px 10px;
  border: #fff 1px solid;
  color: #fff;
  font-size: 1.2rem;
  line-height: 1em;
  border-radius: 10px;
  opacity: 0.8;

  :hover {
    text-decoration: none;
    opacity: 1;
    cursor: pointer;
  }
`;

interface SiteNavProps {
  isHome?: boolean;
}

const SiteNav: React.FC<SiteNavProps> = ({ isHome = false }) => {
  const subscribe = React.createRef<SubscribeModal>();

  const openModal = () => {
    if (subscribe.current) {
      subscribe.current.open();
    }
  };

  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <nav css={[isHome && HomeNavRaise, SiteNavStyles]}>
      <SiteNavLeft>
        {!isHome && <SiteNavLogo home={withLangURL(language, '/')} />}
        <ul css={NavStyles} role="menu">
          {/* TODO: mark current nav item - add class nav-current */}
          <li role="menuitem">
            <Link to={withLangURL(language, '/')}>{t('Home')}</Link>
          </li>
          <li role="menuitem">
            <Link to={withLangURL(language, '/about')}>{t('About')}</Link>
          </li>
          <li role="menuitem">
            <Link to={withLangURL(language, '/tags/tests/')}>Tests</Link>
          </li>
        </ul>
        <LanguageSwitcher />
      </SiteNavLeft>
      <SiteNavRight>
        <SocialLinks>
          {config.facebook && (
            <a
              css={SocialLink}
              href={config.facebook}
              target="_blank"
              title="Facebook"
              rel="noopener noreferrer"
            >
              <Facebook />
            </a>
          )}
          {config.twitter && (
            <a
              css={SocialLink}
              href={config.twitter}
              title="Twitter"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Twitter />
            </a>
          )}
        </SocialLinks>
        {config.showSubscribe && <SubscribeButton onClick={openModal}>Subscribe</SubscribeButton>}
        {config.showSubscribe && <SubscribeModal ref={subscribe} />}
      </SiteNavRight>
    </nav>
  );
};

export default SiteNav;
